<template>
	<div class="goods_apply">
		<e-table :tableCols="goodsApplyTableConf" ref="tables" @query-Table-data="handleCurrentChange" :customSearchList="searchFormList" v-on:resetSearch="resetSearch" v-on:searchParamsChange="searchParamsChange" :showOperation="true" :dataOrigin="dataOrigin" :total="total" :currentPage="currentPage" :condition="form">
			<template #account5="scope">
				<el-popover
					trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #account12="scope">
				<div>
					<span class="el-icon-user-solid"></span>
					<span>{{ scope.rowData.username }}</span>
				</div>
				<div class="pt8 pb8">
					<span class="el-icon-phone"></span>
					<span>{{ scope.rowData.mobile }}</span>
				</div>
				<div>
					<span class="el-icon-chat-dot-round"></span>
					<span>{{ scope.rowData.qq }}</span>
				</div>
			</template>
			<template #account14="scope">
				<el-link type="primary" :href="scope.rowData.item_url" target="_blank">{{scope.rowData.item_url}}</el-link>
			</template>
			<template #expandSearchParams>
				<el-col :span="4" class="flex_r f_ai_c mgb-10">
					<span class="w_75">商品名</span>
					<el-input v-model="form.query" placeholder="请输入商品名" class="w_150"></el-input>
				</el-col>
				<el-col :span="4" class="flex_r f_ai_c mgb-10">
					<span class="w_75">店铺名</span>
					<el-input v-model="form.shop_name" placeholder="请输入店铺名" class="w_150"></el-input>
				</el-col>
				<el-col :span="6" class="flex_r f_ai_c mgb-10">
					<span class="w_75">商品分类</span>
					<el-select v-model="form.cat" placeholder="商品分类">
						<el-option :label="cate.name" :value="cate.id"  v-for="cate in category" :key="cate.index"></el-option>
					</el-select>
				</el-col>
				<el-col :span="6" class="flex_r f_ai_c mgb-10">
					<span class="w_75">商品状态</span>
					<el-select v-model="form.type" placeholder="商品状态">
						<el-option label="待审核" value="0"></el-option>
						<el-option label="审核驳回" value="2"></el-option>
						<el-option label="待寄样" value="1"></el-option>
						<el-option label="已寄样" value="3"></el-option>
						<el-option label="已入库" value="4"></el-option>
						<el-option label="待排期" value="4"></el-option>
						<el-option label="待直播" value="5"></el-option>
						<el-option label="待完成" value="7"></el-option>
						<el-option label="已完成" value="8"></el-option>
					</el-select>
				</el-col>
				<el-col :span="6" class="flex_r f_ai_c mgb-10">
					<span class="w_75">直播价</span>
					<el-input v-model="form.zb_price" class="w_150"></el-input>
					<span class="pl4 pr4">~</span>
					<el-input v-model="form.zb_e_price" class="w_150"></el-input>
				</el-col>
				<el-col :span="8" class="flex_r f_ai_c mgb-10">
					<span class="w_75">佣金比例</span>
					<el-input v-model="form.s_rate" class="w_150"> <template slot="append">%</template></el-input>
					<span class="pl4 pr4">~</span>
					<el-input v-model="form.e_rate" class="w_150"> <template slot="append">%</template></el-input>
				</el-col>
				<el-col :span="6" class="flex_r f_ai_c mgb-10">
					<span class="w_75">服务费</span>
					<el-input v-model="form.s_fee" class="w_150"></el-input>
					<span class="pl4 pr4">~</span>
					<el-input v-model="form.e_fee" class="w_150"></el-input>
				</el-col>
			</template>
			<template #content="scope">
				<span v-html="scope.rowData.content"></span>
			</template>
			<template #customOperation>
<!--				<el-col :span="1">-->
<!--					<el-button>批量邀请</el-button>-->
<!--				</el-col>-->
			</template>
			<template #operation="scope">
				<div class="cur_p" @click="$refs.dialogRef.handleOpen()">商家排期</div>
				<div class="cur_p" @click="yq(scope.rowData.shop_user_id)">邀请报名</div>
			</template>
		</e-table>
		<e-dialog title="商家排期" ref="dialogRef" width="1200px" @confirm="DialogConfirm">
			<div>
				<el-form>
					<el-form-item label="直播日期：">
						<el-date-picker
						v-model="value1"
						type="date"
						placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-form>
				<div class="flex_r">
					<span>选择排期：</span>
					<e-table :showSelectionCol="true" class="flex1" :tableCols="goodsApplyArrangeTableConf" :dataOrigin="goodsApplyArrangeDataOrigin"></e-table>
				</div>
			</div>
		</e-dialog>
	</div>
</template>

<script>
import {goodsApplyTableConf, searchFormList, goodsApplyArrangeTableConf} from '@data/goods'
export default {
	name: 'GoodsApply',
	data() {
		return {
			goodsApplyTableConf,
			goodsApplyArrangeTableConf,
			searchFormList,
			dataOrigin: {
				data: []
			},
			goodsApplyArrangeDataOrigin: {
				data: [
				]
			},
			currentPage: 1,
			pageSize: 10,
			total: 0,
			goodsLists: [],
			category: [],
			form: {}
		}
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetGoodsLists()
			this.GetCategoryLists()
		});
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetGoodsLists()
		},
		resetSearch(){
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.$refs.tables.loading = true
			this.GetGoodsLists()
		},
		searchParamsChange(e) {
			this.condition = e.condition
			this.currentPage = 1
			this.$refs.tables.loading = true
			this.GetGoodsLists()
		},
		yq(id){
			let that = this

			this.$confirm('是否邀请该商家报名?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.invite.user.handle.post',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '邀请成功!'
					});
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消邀请'
				});
			});
		},
		searchGoods() {
			this.currentPage = 1
			this.GetGoodsLists()
		},
		handleClick() {},
		DialogConfirm() {
			this.$refs.dialogRef.handleClose();
		},
		GetCategoryLists(){
			let that = this
			this.category = []
			this.get('?server=live.goods.category.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.category = response.data
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetGoodsLists(){
			let that = this
			this.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.goods.lists.get',{type:this.activeName,
				stype:this.form.type,
				page:this.currentPage,
				query:this.form.query,
				shop_name:this.form.shop_name,
				cat:this.form.cat,
				rate:this.form.rate,
				web:this.form.web,
				zb_price:this.form.zb_price,
				zb_e_price:this.form.zb_e_price,
				s_xse:this.form.s_xse,
				e_xse:this.form.e_xse,
				s_volume:this.form.s_volume,
				e_volume:this.form.e_volume,
				s_zbtimes:this.form.s_zbtimes,
				e_zbtimes:this.form.e_zbtimes,
				s_rate:this.form.s_rate,
				e_rate:this.form.e_rate,
				s_fee:this.form.s_fee,
				e_fee:this.form.e_fee,
				baotime:this.form.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	}
}
</script>

<style lang="scss" scoped>
	.mgb-10 {
		margin-bottom: 10px;
	}
</style>